<template>
  <el-dialog :fullscreen="true" :visible.sync="visible" custom-class="full" @close="closeDialog">
    <div class="bg">
      <div class="center">
        <div class="title">您已完成入驻</div>
        <div class="middle-title">优质的服务 换取多的用户</div>
        <div class="sub-title">开启自由工作 雇人用因您更加多彩</div>
        <div class="bottom">
          <div class="item">
            <span>国内正规</span>
            <span>雇佣平台</span>
          </div>
          <div class="item">
            <span>上百种</span>
            <span>服务项目</span>
          </div>
          <div class="item">
            <span>助力企业</span>
            <span>降本增效</span>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: true
    }
  },
  methods: {
    closeDialog() {
      this.visible = false
      this.$router.push({name: 'ServiceHall'})
    }
  }
}
</script>
<style lang="less">
.full {
  .el-dialog__header {
    padding: 0;
  }
  .el-dialog__body {
    padding: 0;
  }
  .el-dialog__close {
    font-size: 50px;
  }
}
</style>
<style scoped lang="less">
.bg {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-image: url("https://www.gurenyong.com/admin-api/infra/file/5/get/03afc6d2-64be-4207-a25c-f7bbead9259d.png");
  color: #ffffff;
  
  display: flex;
  justify-content: center;
  align-items: center;
  .center {
    width: 100%;
    text-align: center;
    position: relative;
  }
  .title {
    font-weight: bold;
    position: absolute;
    font-size: 80px;
    left: 0;
    right: 0;
    bottom: 200px;
  }
  .title {
    font-weight: bold;
    position: absolute;
    font-size: 80px;
    left: 0;
    right: 0;
    bottom: 200px;
  }
  .middle-title {
    font-size: 60px;
  }
  .sub-title {
    position: absolute;
    font-size: 50px;
    top: 100px;
    left: 0;
    right: 0;
  }
  .bottom {
    position: fixed;
    bottom: 120px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    .item {
      margin: 0 100px;
      font-size: 26px;
      display: flex;
      flex-direction: column;
      align-items: center;
      span {
        margin: 5px 0;
      }
    }
  }
}
</style>
